<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('inventoryLogFilter')"
                  >
                    <CIcon v-if="!loading" size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="primary" square
                       size="sm"
                       @click="getExcel"
              >
                Excel'e Aktar
              </CButton>
            </div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CDataTable
            :items="inventoryLogs"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            v-on:refresh="filterInventoryLogs"
          >
            <template #inventoryLogCreateTime="{item}">
             <td> {{ item.inventoryLogCreateTime && dateFormat(item.inventoryLogCreateTime) }} </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterInventoryLogs"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import FilterSidebar from '../components/filterSidebar'
import { prepareFilterParams } from "@/helpers/custom"
import moment from "moment";

export default {
  name: 'InventoryLog',
  components: {FilterSidebar},
  data() {
    return {
      module: 'inventory',
      lastItem: {},
      openSidebar: false,
      filterForm: 'inventoryLogFilter',
      filterParams: {},
      activePage: 1,
      pages: 5,
      itemsPerPage: 10,
      fields: [
        {key: 'inventoryLogId', label: 'Id', _style: 'font-size:12px'},
        {key: 'inventoryLogCreateTime', label: 'Tarih', _style: 'font-size:12px'},
        {key: 'posName', label: 'Stok Alanı', _style: 'font-size:12px'},
        {key: 'productName', label: 'SKU Ürün Adı', _style: 'font-size:12px'},
        {key: 'inventoryLogStatus', label: 'Durum', _style: 'font-size:12px'},
        {key: 'inventoryLogDesc', label: 'Açıklama', _style: 'font-size:12px'},
        {key: 'inventoryLogAmount', label: 'Adet', _style: 'font-size:12px'},
        {key: 'skuName', label: 'SKU Adı', _style: 'font-size:12px'},
        {key: 'skuCode', label: 'SKU Kodu', _style: 'font-size:12px'},
        {key: 'skuBarcode', label: 'SKU Barkod', _style: 'font-size:12px'}
      ],
      filters: [
        {
          type: 'integer',
          dataIndex: 'inventoryLogId',
          field: 'inventoryLogId',
          comparison: 'eq'
        }, {
          type: 'string',
          dataIndex: 'posName',
          field: 'posName'
        }, {
          type: 'string',
          dataIndex: 'productName',
          field: 'productName'
        }, {
          type: 'integer',
          dataIndex: 'inventoryLogAmount',
          field: 'inventoryLogAmount',
          comparison: 'eq'
        }, {
          type: 'string',
          dataIndex: 'skuName',
          field: 'skuName'
        }, {
          type: 'string',
          dataIndex: 'skuCode',
          field: 'skuCode'
        }, {
          type: 'string',
          dataIndex: 'skuBarcode',
          field: 'skuBarcode'
        }
      ],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },

  methods: {
    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },

    rowClicked(item, index) {
      this.lastItem = item
    },
    
    async getExcel() {
      let params = {
        responseType: 'blob'
      }
      await this.$store.dispatch('inventoryLog_excel', params);
      if(this.$store.getters.inventoryStatus){
        const url = window.URL.createObjectURL(new Blob([this.$store.getters.inventoryStatus]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'StokLog_' + moment(new Date).format('DD/MM/YYYY HH:mm:ss') + '.xls');
        document.body.appendChild(link);
        link.click();
      }
    },

    async filterInventoryLogs(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      await this.$store.dispatch('inventoryLog_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },

  },
  mounted() {
    this.filterInventoryLogs(this.filterParams);
  },
  computed: {
    reloadParams() {
      return [
        this.activePage
      ]
    },
    inventoryLogs(){
      return this.$store.getters.inventoryLogs
    },
    loading(){
      return this.$store.getters.inventoryLoading
    }
  },
}
</script>
<style>
input,
select {
  padding: 0.45em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
}
</style>
